import { clsx } from 'clsx'
import { parse } from 'papaparse'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { usePDF } from '@react-pdf/renderer'

import { CSVRow } from 'types/internal'
import { PurchasedGoodAllergen } from 'types/combinedAPI/domainModels'
import { error } from 'slices/notifications'
import { generate128Barcode } from 'utils/barcodes'

import { useAppDispatch } from 'hooks'
import { usePurchasedGoodByBaseSKU } from 'hooks/combinedAPI/purchasedGoods'
import APIErrorDisplay from 'components/common/APIErrorDisplay'
import CircleLoader from 'components/common/CircleLoader'
import DocumentTextIcon from 'components/common/icons/DocumentTextIcon'
import ErrorDisplay from 'components/common/ErrorDisplay'
import StockLabelsPDF from './StockLabelsPDF'

const StockLabelsPage = (): JSX.Element => {
  return (
    <div className="mx-auto max-w-content px-4 pb-40 pt-8">
      <div className="mb-4">
        <h1 className="text-2xl">Upload from CSV</h1>
      </div>
      <div className="w-3/4">
        <p className="text-sm">
          Upload a CSV containing data for stock labels below. Each product in
          the CSV can then have labels printed for it. Note that a max of 20
          labels will be generated (if you need more, print additional copies of
          the generated PDF).
        </p>
        <div className="my-4 space-y-4">
          <CSVParser />
        </div>
      </div>
    </div>
  )
}

export default StockLabelsPage

const CSVParser = (): JSX.Element => {
  const [csvData, setCsvData] = useState<CSVRow[]>([])
  const [csvFilename, setCsvFilename] = useState('')
  const [csvLoadError, setCsvLoadError] = useState('')

  const onDrop = useCallback((acceptedFiles: File[]) => {
    function onCSVLoadError() {
      setCsvLoadError('There was an error reading the CSV. Please try again.')
    }

    acceptedFiles.forEach((file) => {
      setCsvFilename(file.name)

      const reader = new FileReader()

      reader.onabort = onCSVLoadError
      reader.onerror = onCSVLoadError
      reader.onload = () => {
        setCsvData(
          parse(reader.result as string, {
            header: true,
          }).data as CSVRow[]
        )
      }
      reader.readAsText(file)
    })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
  })

  if (csvData.length > 0 && csvLoadError) {
    return (
      <ErrorDisplay>
        Failed to load data for CSV parsing. Error: {csvLoadError}
      </ErrorDisplay>
    )
  }

  return (
    <>
      <div
        {...getRootProps()}
        className={clsx(
          'flex cursor-pointer justify-center border border-dashed p-8 text-sm text-grey',
          {
            'border-orange': isDragActive,
            'border-light-grey': !isDragActive,
          }
        )}
      >
        <input data-testid="upload-csv-input" {...getInputProps()} />
        <div>
          {isDragActive ? (
            'Drop files here...'
          ) : (
            <div className="flex items-center space-x-2">
              <div className="h-4 w-4 text-grey">
                <DocumentTextIcon />
              </div>
              <span>Drag & Drop a CSV here or click to select</span>
            </div>
          )}
        </div>
      </div>
      {csvFilename && (
        <div className="space-x-2 text-sm">
          <span className="uppercase text-grey">Current File:</span>
          <span>{csvFilename}</span>
        </div>
      )}
      {csvData.length > 0 && <ListProducts csvData={csvData} />}
    </>
  )
}

const ListProducts = ({ csvData }: { csvData: CSVRow[] }): JSX.Element => {
  const filteredCSVRows = csvData.filter(({ Product }) => !!Product)

  return (
    <div className="divide-y divide-grey2">
      {filteredCSVRows.map((csvRow, i) => {
        return (
          <div key={i} className="py-2 first:pt-0 last:pb-0">
            <ProductRow csvRow={csvRow} />
          </div>
        )
      })}
    </div>
  )
}

const ProductRow = ({ csvRow }: { csvRow: CSVRow }): JSX.Element => {
  const dispatch = useAppDispatch()

  const [hasRequestedPrint, setHasRequestedPrint] = useState(false)

  const {
    data: purchasedGood,
    error: getPurchasedGoodError,
    isError: hasGetPurchasedGoodError,
    isLoading: isLoadingPurchasedGood,
  } = usePurchasedGoodByBaseSKU({
    baseSKU: csvRow.Product,
  })

  const onFailed = useCallback(
    (errorMessage: string) => {
      dispatch(
        error(`Failed to generate stock label PDF. Error: ${errorMessage}`)
      )

      setHasRequestedPrint(false)
    },
    [dispatch]
  )

  const onPrinted = useCallback(() => {
    setHasRequestedPrint(false)
  }, [])

  const rowMissingFields: string[] = []
  if (!csvRow.Batch) {
    rowMissingFields.push('Batch')
  }
  if (!csvRow.Vendor) {
    rowMissingFields.push('Vendor')
  }

  return (
    <div className="space-y-2">
      <div className="flex space-x-4">
        <div className="w-1/2">
          {csvRow.Description} ({csvRow.Product})
        </div>
        <div className="w-32">Boxes: {csvRow.Boxes}</div>
        {hasRequestedPrint ? (
          <PrintLabel
            allergens={purchasedGood?.allergens || []}
            csvRow={csvRow}
            onFailed={onFailed}
            onPrinted={onPrinted}
          />
        ) : rowMissingFields.length === 0 ? (
          <>
            {isLoadingPurchasedGood ? (
              <CircleLoader loaderStyle="colored" />
            ) : (
              <a
                className="cursor-pointer text-blue underline"
                onClick={() => {
                  setHasRequestedPrint(true)
                }}
              >
                Print
              </a>
            )}
          </>
        ) : null}
      </div>

      {rowMissingFields.length > 0 && (
        <ErrorDisplay>
          The following fields are missing in Snap:{' '}
          {rowMissingFields.join(', ')}
        </ErrorDisplay>
      )}

      {hasGetPurchasedGoodError && (
        <APIErrorDisplay
          error={getPurchasedGoodError}
          fallbackError="Could not get purchased good allergens"
        />
      )}
    </div>
  )
}

const PrintLabel = ({
  allergens,
  csvRow,
  onFailed,
  onPrinted,
}: {
  allergens: PurchasedGoodAllergen[]
  csvRow: CSVRow
  onFailed(message: string): void
  onPrinted(): void
}) => {
  const barcodes = useMemo(() => {
    return {
      batch: generate128Barcode(csvRow.Batch),
      caseWeight: generate128Barcode(csvRow['Box Qty']),
      product: generate128Barcode(csvRow.Product),
      stu: csvRow.StU ? generate128Barcode(csvRow.StU) : '',
      vendor: generate128Barcode(csvRow.Vendor),
    }
  }, [csvRow])

  const [instance] = usePDF({
    document: (
      <StockLabelsPDF
        allergens={allergens}
        barcodes={barcodes}
        csvRow={csvRow}
      />
    ),
  })

  useEffect(() => {
    if (instance.url) {
      const win = window.open(instance.url)
      win?.print()
      onPrinted()
    }
  }, [instance.url, onPrinted])

  useEffect(() => {
    if (instance.error) {
      onFailed(instance.error)
    }
  }, [instance.error, onFailed])

  return <CircleLoader loaderStyle="colored" />
}

import { flatten, isEmpty, uniqBy } from 'lodash-es'
import { FormikErrors } from 'formik'

import {
  BOMItem,
  ProductionCycle,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'
import { ReactSelectValue } from 'types/internal'

export interface FormData {
  facility: ReactSelectValue<string> | null
  purchasedGoods: {
    clientSideID: string
    count: number | ''
    reason: string
    item: ReactSelectValue<BOMItem>
  }[]
}

export interface FormDataValidated {
  facility: ReactSelectValue<string>
  purchasedGoods: {
    clientSideID: string
    count: number
    item: ReactSelectValue<BOMItem>
    reason: string
  }[]
}

export type Stage = 'creating' | 'error' | 'success'
export interface Stages {
  [itemID: string]: {
    error: Error | undefined
    stage: Stage
  }
}

export function getShortPickOrderOptions({
  productionCycle,
  productionRecord,
}: {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
}): {
  purchasedGoods: ReactSelectValue<BOMItem>[]
} {
  const { meals, parts } = productionRecord.cycles[productionCycle]

  const purchasedGoodOptions = uniqBy(
    flatten(
      [...meals, ...parts].map((item) => {
        return parseBillOfMaterialsForPurchasedGoods({
          billOfMaterials: item.billOfMaterials,
        })
      })
    ),
    'label'
  )

  return {
    purchasedGoods: purchasedGoodOptions,
  }
}

function parseBillOfMaterialsForPurchasedGoods({
  billOfMaterials,
}: {
  billOfMaterials: BOMItem[]
}): ReactSelectValue<BOMItem>[] {
  return billOfMaterials
    .filter((bom) => {
      return !bom.productionPartID
    })
    .map((purchasedGood) => {
      return {
        label: purchasedGood.title,
        value: purchasedGood,
      }
    })
}

export function validateData(formData: FormData): FormikErrors<FormData> {
  const errors: FormikErrors<FormData> = {}

  const purchasedGoodsErrors: FormikErrors<
    FormData['purchasedGoods'][number]
  >[] = []

  if (!formData.facility) {
    errors.facility = 'Please select a facility.'
  }

  if (formData.purchasedGoods.length === 0) {
    errors.purchasedGoods = 'Please select a purchased good.'
  } else {
    formData.purchasedGoods.map((purchasedGood) => {
      const errors: FormikErrors<FormData['purchasedGoods'][number]> = {}

      if (purchasedGood.count === '') {
        errors.count = 'Required'
      } else if (purchasedGood.count <= 0) {
        errors.count = 'Must be greater than 0'
      }

      purchasedGoodsErrors.push(errors)
    })
  }

  if (purchasedGoodsErrors.some((errors) => !isEmpty(errors))) {
    errors.purchasedGoods = purchasedGoodsErrors
  }

  return errors
}

import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import { times } from 'lodash-es'

import { commonPDFStyles } from 'utils/pdfs'
import { CSVRow } from 'types/internal'
import { PurchasedGoodAllergen } from 'types/combinedAPI/domainModels'
import { formatCommas } from 'utils/general'

import stockLabelBG from './stockLabelBG.png'
import eggs from './icons/eggs.png'
import fish from './icons/fish.png'
import milk from './icons/milk.png'
import peanuts from './icons/peanuts.png'
import shellfish from './icons/shellfish.png'
import sesame from './icons/sesame.png'
import soybeans from './icons/soybeans.png'
import treeNuts from './icons/tree_nuts.png'
import wheat from './icons/wheat.png'

const ALLERGEN_MAP = {
  egg: {
    name: 'EGGS',
    image: eggs,
  },
  fish: {
    name: 'FISH',
    image: fish,
  },
  milk: {
    name: 'MILK',
    image: milk,
  },
  peanut: {
    name: 'PEANUTS',
    image: peanuts,
  },
  shellfish: {
    name: 'SHELLFISH',
    image: shellfish,
  },
  sesame_seed: {
    name: 'SESAME',
    image: sesame,
  },
  soy: {
    name: 'SOYBEANS',
    image: soybeans,
  },
  tree_nuts: {
    name: 'TREE NUTS',
    image: treeNuts,
  },
  wheat: {
    name: 'WHEAT',
    image: wheat,
  },
}

const StockLabelsPDF = ({
  allergens,
  barcodes,
  csvRow,
}: {
  allergens: PurchasedGoodAllergen[]
  barcodes: {
    batch: string
    caseWeight: string
    product: string
    stu: string
    vendor: string
  }
  csvRow: CSVRow
}): JSX.Element => {
  const labels = times(Math.min(20, Number(csvRow.Boxes)), () => {
    return csvRow
  })

  return (
    <Document title="stock-labels-pdf">
      {labels.map((label, i) => {
        const caseWeightStr = label['Box Qty']

        // The dates include time information and it's not something we can modify in the CSV we
        // upload (see comments on https://tovala.atlassian.net/browse/WAT-400). We just want to
        // drop the time information since there's no meaningful timezone here.
        const expirationDate = label['Date Expiry'].substring(0, 10)
        const receiptDate = label['Date Receipt'].substring(0, 10)

        return (
          <Page
            key={i}
            orientation="portrait"
            size="A6"
            style={{
              ...commonPDFStyles.page,
              width: '4in',
              height: '6in',
            }}
          >
            <View style={{ position: 'absolute', top: '5px', left: '15px' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Text style={{ fontSize: '20px' }}>Product:</Text>
                <Text style={{ marginLeft: '15px', fontSize: '25px' }}>
                  {label.Product}
                </Text>
              </View>
              <Text
                style={{ fontSize: '17px', textOverflow: 'ellipsis' }}
                wrap={false}
              >
                {label.Description.length > 32
                  ? `${label.Description.substring(0, 32)}...`
                  : label.Description}
              </Text>
              <Image
                src={() => {
                  return barcodes.product
                }}
                style={{
                  width: '250px',
                  height: '55px',
                  textAlign: 'center',
                }}
              />
            </View>
            <View style={{ position: 'absolute', top: '120px', left: '15px' }}>
              <Text style={{ fontSize: '14px' }}>Batch: {label.Batch}</Text>
              <Image
                src={() => {
                  return barcodes.batch
                }}
                style={{
                  width: '250px',
                  height: '55px',
                  textAlign: 'center',
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: '195px',
                right: '10px',
                left: '15px',
                height: '73px',
                padding: '2px 0',
              }}
            >
              <View style={{ marginBottom: '2px' }}>
                <Text style={{ fontSize: '13px' }}>
                  Vendor Info: {label.Vendor}
                </Text>
              </View>
              <View style={{ flexGrow: 1 }}>
                <Image
                  src={() => {
                    return barcodes.vendor
                  }}
                  style={{
                    width: '250px',
                    textAlign: 'center',
                  }}
                />
              </View>
            </View>
            <View
              style={{
                position: 'absolute',
                top: '272px',
                left: '15px',
              }}
            >
              <View style={{ position: 'relative', marginBottom: '5px' }}>
                <Text style={{ fontSize: '13px' }}>Case Weight:</Text>
                <View
                  style={{
                    position: 'absolute',
                    top: '0px',
                    right: '14px',
                    width: '30px',
                    fontSize: '7px',
                  }}
                >
                  <View>
                    <Text>*(Box Qty</Text>
                  </View>
                  <View>
                    <Text>in Snap):</Text>
                  </View>
                </View>
              </View>
              <Text style={{ fontSize: '15px' }}>
                {formatCommas(Number(caseWeightStr))} lbs/case
              </Text>
              <Image
                src={() => {
                  // We intentionially use whatever is in the uploaded CSV here. We don't want any rounding.
                  return barcodes.caseWeight
                }}
                style={{
                  position: 'relative',
                  left: '-12px',
                  width: '125px',
                  height: '35px',
                }}
              />
            </View>
            <View
              style={{
                position: 'absolute',
                top: '272px',
                left: '134px',
              }}
            >
              <Text style={{ fontSize: '12px' }}>Rec: {receiptDate}</Text>
              <Text
                style={{ fontSize: '20px', position: 'relative', top: '-4px' }}
              >
                Exp: {expirationDate}
              </Text>
            </View>
            <View
              style={{
                position: 'absolute',
                top: '306px',
                left: '130px',
              }}
            >
              {allergens.includes('shellfish') && (
                <AllergenIcon allergen="shellfish" left={0} top={4} />
              )}
              {allergens.includes('egg') && (
                <AllergenIcon allergen="egg" left={55} top={4} />
              )}
              {allergens.includes('fish') && (
                <AllergenIcon allergen="fish" left={110} top={4} />
              )}
              {allergens.includes('milk') && (
                <AllergenIcon allergen="milk" left={0} top={42} />
              )}
              {allergens.includes('peanut') && (
                <AllergenIcon allergen="peanut" left={55} top={42} />
              )}
              {allergens.includes('sesame_seed') && (
                <AllergenIcon allergen="sesame_seed" left={110} top={42} />
              )}
              {allergens.includes('soy') && (
                <AllergenIcon allergen="soy" left={0} top={77} />
              )}
              {allergens.includes('tree_nuts') && (
                <AllergenIcon allergen="tree_nuts" left={55} top={77} />
              )}
              {allergens.includes('wheat') && (
                <AllergenIcon allergen="wheat" left={110} top={77} />
              )}
            </View>
            <View
              style={{
                position: 'absolute',
                top: '355px',
                left: '15px',
              }}
            >
              <Text style={{ fontSize: '16px' }}>StU: {label.StU}</Text>
              {label.StU ? (
                <Image
                  src={() => {
                    return barcodes.stu
                  }}
                  style={{
                    width: '126px',
                    height: '35px',
                    position: 'relative',
                    left: '-10px',
                  }}
                />
              ) : (
                <Text />
              )}
            </View>
            <Image
              src={stockLabelBG}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          </Page>
        )
      })}
    </Document>
  )
}

export default StockLabelsPDF

const AllergenIcon = ({
  allergen,
  left,
  top,
}: {
  allergen: PurchasedGoodAllergen
  left: number
  top: number
}) => {
  const allergenInfo = ALLERGEN_MAP[allergen]

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '55px',
        position: 'absolute',
        left: `${left}px`,
        top: `${top}px`,
      }}
    >
      <Image
        src={allergenInfo.image}
        style={{
          height: '20px',
          position: 'relative',
          top: '-2px',
        }}
      />
      <Text style={{ fontSize: '9px' }}>{allergenInfo.name}</Text>
    </View>
  )
}

import { groupBy, sum, sumBy } from 'lodash-es'

import {
  Facility,
  FacilityNetwork,
  ProductionCycle,
  ProductionPart,
  ProductionRecord,
} from 'types/combinedAPI/domainModels'
import { filterSleevingFacilities } from 'utils/facility-networks'

import Hyperlink from 'components/common/bs/Hyperlink'

function getPartArrays({
  productionCycle,
  productionRecord,
}: {
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
}) {
  const allParts: ProductionPart[] = []
  const batched: ProductionPart[] = []
  const combinedParts: ProductionPart[] = []
  const prepLocationParts: ProductionPart[] = []
  const scheduled: ProductionPart[] = []
  const taggedParts: ProductionPart[] = []
  const unBatched: ProductionPart[] = []
  const unCombinedParts: ProductionPart[] = []
  const unLocatedParts: ProductionPart[] = []
  const unScheduled: ProductionPart[] = []
  const unTaggedParts: ProductionPart[] = []

  productionRecord.cycles[productionCycle].parts.map((part) => {
    const resolvedPart = part.combined
      ? productionRecord.combinedParts[part.id]
      : part

    allParts.push(resolvedPart)

    if (resolvedPart.combined) {
      combinedParts.push(resolvedPart)
    } else {
      unCombinedParts.push(resolvedPart)
    }

    if (resolvedPart.numBatches > 0) {
      batched.push(resolvedPart)
    } else {
      unBatched.push(resolvedPart)
    }

    const prepLocationTag = resolvedPart.tags.find(
      ({ category }) => category === 'prep_location'
    )
    if (prepLocationTag) {
      prepLocationParts.push(resolvedPart)
    } else {
      unLocatedParts.push(resolvedPart)
    }

    const dayOfWeekTag = resolvedPart.tags.find(
      ({ category }) => category === 'day_of_week'
    )
    if (dayOfWeekTag) {
      scheduled.push(resolvedPart)
    } else {
      unScheduled.push(resolvedPart)
    }

    const consideredTaggedTag = resolvedPart.tags.find((tag) => {
      return (
        tag.category !== 'day_of_week' &&
        tag.category !== 'prep_location' &&
        tag.title !== 'do_not_label'
      )
    })
    if (consideredTaggedTag) {
      taggedParts.push(resolvedPart)
    } else {
      unTaggedParts.push(resolvedPart)
    }
  })

  return {
    allParts,
    batched,
    combinedParts,
    prepLocationParts,
    scheduled,
    taggedParts,
    unBatched,
    unCombinedParts,
    unLocatedParts,
    unScheduled,
    unTaggedParts,
  }
}

const QaChecklist = ({
  facilityNetwork,
  onClickViewParts,
  productionCycle,
  productionRecord,
}: {
  facilityNetwork: FacilityNetwork
  onClickViewParts(partIDs: string[]): void
  productionCycle: ProductionCycle
  productionRecord: ProductionRecord
}): JSX.Element => {
  const sleevingFacilities = filterSleevingFacilities(facilityNetwork).ofType

  const meals = productionRecord.cycles[productionCycle].meals
  const mealsByFacility = groupBy(
    meals,
    (meal) => meal.tags.find((t) => t.category === 'sleeving_location')?.title
  )

  const {
    allParts,
    batched,
    combinedParts,
    prepLocationParts,
    scheduled,
    taggedParts,
    unBatched,
    unCombinedParts,
    unLocatedParts,
    unScheduled,
    unTaggedParts,
  } = getPartArrays({ productionCycle, productionRecord })

  function _getSleeveTotal(facility: Facility): number {
    const facilitySleevedTotal = meals.filter(
      (meal) =>
        !!meal.tags.find(
          (t) =>
            t.category === 'sleeving_location' && t.title === facility.title
        )
    )

    return sumBy(facilitySleevedTotal, (m) => m.totalMeals)
  }

  function _getContainerTotal(
    facility: Facility,
    container:
      | 'dry sachet'
      | 'sachet'
      | 'tray 1 dry sachet'
      | 'tray 2 dry sachet'
      | 'tray 1 sachet'
      | 'tray 2 sachet'
  ) {
    const sachetParts =
      mealsByFacility[facility.title]?.filter((p) => {
        return !!p.billOfMaterials.find((bom) => {
          return bom.tags.find(
            (t) => t.category === 'container' && t.title === container
          )
        })
      }) ?? []
    const sachetCounts = sachetParts.map((p) => {
      const numberOfSachetBoms = p.billOfMaterials.filter((b) =>
        b.tags.find((t) => t.category === 'container' && t.title === container)
      ).length
      const mealCount =
        meals.find((m) => m.mealCode === p.mealCode)?.totalMeals ?? 0

      return numberOfSachetBoms * mealCount
    })

    return sum(sachetCounts)
  }

  const facilitySachetCounts: { [facility: string]: number } = {}
  sleevingFacilities.forEach((facility) => {
    facilitySachetCounts[facility.title] =
      _getContainerTotal(facility, 'sachet') +
      _getContainerTotal(facility, 'tray 1 sachet') +
      _getContainerTotal(facility, 'tray 2 sachet')
  })

  const facilityBinCounts: { [facility: string]: { redBins: number; blueBins: number; formattedBlueBinMealCodes: number[][] } } = {}
  sleevingFacilities.forEach((facility) => {
    const redBinMeals = mealsByFacility[facility.title]?.filter((meal) =>
      meal.tags.some((tag) => tag.category === 'sleeving_bins_configuration' && tag.title === 'Red Bins')
    ) ?? []
    const blueBinMeals = mealsByFacility[facility.title]?.filter((meal) =>
      meal.tags.some((tag) => tag.category === 'sleeving_bins_configuration' && tag.title === 'Blue Bins')
    ) ?? []

    facilityBinCounts[facility.title] = {
      redBins: sumBy(redBinMeals, (m) => m.totalMeals),
      blueBins: sumBy(blueBinMeals, (m) => m.totalMeals),
      formattedBlueBinMealCodes: [],
    }

    const blueBinMealCodes = blueBinMeals.map((meal) => Math.floor(meal.mealCode / 100))
    const formattedBlueBinMealCodes = blueBinMealCodes.reduce<number[][]>((acc, code, index) => {
      const lineIndex = Math.floor(index / 9)
      if (!acc[lineIndex]) {
        acc[lineIndex] = []
      }
      acc[lineIndex].push(code)
      return acc
    }, [])

    facilityBinCounts[facility.title].formattedBlueBinMealCodes = formattedBlueBinMealCodes
  })

  const allBlueBinMealCodes: number[] = []
  sleevingFacilities.forEach((facility) => {
    const blueBinMeals = mealsByFacility[facility.title]?.filter((meal) =>
      meal.tags.some((tag) => tag.category === 'sleeving_bins_configuration' && tag.title === 'Blue Bins')
    ) ?? []

    const blueBinMealCodes = blueBinMeals.map((meal) => Math.floor(meal.mealCode / 100))
    allBlueBinMealCodes.push(...blueBinMealCodes)
  })

  allBlueBinMealCodes.sort((a, b) => a - b)

  const formattedAllBlueBinMealCodes = allBlueBinMealCodes.reduce<number[][]>((acc, code, index) => {
    const lineIndex = Math.floor(index / 9)
    if (!acc[lineIndex]) {
      acc[lineIndex] = []
    }
    acc[lineIndex].push(code)
    return acc
  }, [])

  return (
    <div className="-mx-4 mt-5 flex flex-wrap print:hidden">
      {sleevingFacilities.map((facility) => (
        <div
          key={facility.title}
          className="w-2/12 px-4"
          data-testid="sleeving-facility"
        >
          <b>{facility.displayName}</b>
          <br />
          <span>
            Total Sleeved:{' '}
            <i data-testid={`${facility.title}-total-sleeved`}>
              {_getSleeveTotal(facility)}
            </i>
          </span>
          <br />
          <span>
            Total Sachet:{' '}
            <i data-testid={`${facility.title}-total-sachet`}>
              {facilitySachetCounts[facility.title]}
            </i>
          </span>
          <br />
          <span>
            Total Dry Sachet:{' '}
            <i data-testid={`${facility.title}-total-dry-sachet`}>
              {_getContainerTotal(facility, 'dry sachet') +
                _getContainerTotal(facility, 'tray 1 dry sachet') +
                _getContainerTotal(facility, 'tray 2 dry sachet')}
            </i>
          </span>
          <br />
          <span>
            Total Red Bins:{' '}
            <i data-testid={`${facility.title}-total-red-bins`}>
              {facilityBinCounts[facility.title].redBins}
            </i>
          </span>
          <br />
          <span>
            Total Blue Bins:{' '}
            <i data-testid={`${facility.title}-total-blue-bins`}>
              {facilityBinCounts[facility.title].blueBins}
            </i>
          </span>
          <br />
        </div>
      ))}
      <div className="w-2/12 px-4">
        <b>Totals</b>
        <br />
        <span>Meals Created: </span>
        <i data-testid="total-meals-created">{meals.length}</i>
        <br />
        <span>Sachets: </span>{' '}
        <i data-testid="total-sachets">
          {sum(Object.values(facilitySachetCounts))}
        </i>
        <br />
        <span>
          Meal Count:{' '}
          <i data-testid="total-meal-count">
            {sumBy(meals, (m) => m.totalMeals)}
          </i>
        </span>
        <br />
      </div>
      <div className="w-2/12 px-4">
        <b>Part Tag Checks</b>
        <br />
        <span>Batched: </span>
        <Hyperlink
          data-testid="batched"
          onClick={() => onClickViewParts(unBatched.map(({ id }) => id))}
        >
          {`${batched.length}/${allParts.length}`}
        </Hyperlink>
        <br />
        <span>Combined: </span>
        <Hyperlink
          data-testid="combined"
          onClick={() => onClickViewParts(unCombinedParts.map(({ id }) => id))}
        >
          {combinedParts.length}
        </Hyperlink>
        <br />
        <span>Prep Location: </span>{' '}
        <Hyperlink
          data-testid="prep-location"
          onClick={() => onClickViewParts(unLocatedParts.map(({ id }) => id))}
        >
          {`${prepLocationParts.length}/${allParts.length}`}
        </Hyperlink>
        <br />
        <span>Prep Day: </span>{' '}
        <Hyperlink
          data-testid="prep-day"
          onClick={() => onClickViewParts(unScheduled.map(({ id }) => id))}
        >
          {`${scheduled.length}/${allParts.length}`}
        </Hyperlink>
        <br />
        <span>Production: </span>
        <Hyperlink
          data-testid="production"
          onClick={() => onClickViewParts(unTaggedParts.map(({ id }) => id))}
        >
          {`${taggedParts.length}/${allParts.length}`}
        </Hyperlink>
      </div>
      <div className="w-2/12 px-4">
        <b>Blue Bins</b>
        <br />
        {formattedAllBlueBinMealCodes.map((line, index) => (
          <span key={index}>
            {line.join(',')}
            <br />
          </span>
        ))}
      </div>
    </div>
  )
}

export default QaChecklist

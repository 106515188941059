import { get, post } from './baseAPI'
import { PurchasedGood } from 'types/combinedAPI/domainModels'

export interface GetPurchasedGoodByBaseSKURequest {
  baseSKU: string
}
export type GetPurchasedGoodByBaseSKUResponse = PurchasedGood

export interface SearchPurchasedGoods {
  vendorID: string
}

export type SearchPurchasedGoodsResponse = PurchasedGood[]

export const ENDPOINTS = {
  GET_PURCHASED_GOOD_BY_BASESKU: ({
    baseSKU,
  }: Pick<GetPurchasedGoodByBaseSKURequest, 'baseSKU'>) => ({
    path: `/purchasedGoods/${baseSKU}`,
    version: 'v0' as const,
  }),
  SEARCH_PURCHASED_GOODS: () => ({
    path: '/purchasedGoods/search',
    version: 'v0' as const,
  }),
}

export async function getPurchasedGoodByBaseSKU({
  baseSKU,
}: GetPurchasedGoodByBaseSKURequest) {
  return get<GetPurchasedGoodByBaseSKUResponse>(
    ENDPOINTS.GET_PURCHASED_GOOD_BY_BASESKU({ baseSKU })
  )
}

export async function searchPurchasedGoods({
  vendorID,
}: SearchPurchasedGoods): Promise<SearchPurchasedGoodsResponse> {
  const response = await post<{ data: PurchasedGood[] }>({
    data: { property: 'vendorID', value: vendorID },
    ...ENDPOINTS.SEARCH_PURCHASED_GOODS(),
  })

  return response.data
}

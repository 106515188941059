import { assign, createMachine } from 'xstate'

import {
  createShortPickOrderPurchasedGood,
  CreateShortPickOrderPurchasedGoodResponse,
} from 'services/combinedAPI/pickOrders'
import { FormDataValidated, Stages } from './helpers'
import { ProductionCycle } from 'types/combinedAPI/domainModels'

interface Context {
  currentCreationIndex: number
  facilityID: string
  facilityNetworkID: string
  productionCycle: ProductionCycle
  productionTerm: string
  purchasedGoods: FormDataValidated['purchasedGoods']
  stages: Stages
}

type Events = {
  facilityID: string
  facilityNetworkID: string
  productionCycle: ProductionCycle
  productionTerm: string
  purchasedGoods: FormDataValidated['purchasedGoods']
  type: 'CREATE'
}

type Services = {
  createShortPickOrder: {
    data: CreateShortPickOrderPurchasedGoodResponse
  }
}

function updateStages({
  context,
  error,
}: {
  context: Context
  error?: Error | undefined
}): Context['stages'] {
  return {
    ...context.stages,
    [context.purchasedGoods[context.currentCreationIndex].clientSideID]: {
      error,
      stage: error ? 'error' : 'success',
    },
  }
}

export const shortPickOrderMachine = createMachine<Context, Events>(
  {
    id: 'shortPickOrder',
    initial: 'editing',
    context: {
      currentCreationIndex: 0,
      facilityID: '',
      facilityNetworkID: '',
      productionCycle: '1',
      productionTerm: '',
      purchasedGoods: [],
      stages: {},
    },
    predictableActionArguments: true,
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    states: {
      editing: {
        on: {
          CREATE: {
            actions: ['initCreateState'],
            target: 'creating',
          },
        },
      },
      creating: {
        invoke: {
          src: 'createShortPickOrder',
          onDone: {
            actions: ['markSuccess', 'incrementCurrentCreationIndex'],
            target: 'checkingForMoreItems',
          },
          onError: {
            actions: ['markError', 'incrementCurrentCreationIndex'],
            target: 'checkingForMoreItems',
          },
        },
      },
      checkingForMoreItems: {
        always: [
          { cond: 'hasMoreItemsToCreate', target: 'delayCreate' },
          { target: 'created' },
        ],
      },
      // We add a 5 second delay because the back-end needs time to process each request. We know this is not ideal and won't
      // work if multiple people create short pick orders in different tabs at the same time. However, we're willing to use
      // this for now and refactor to be more reliable later when we have more bandwidth.
      delayCreate: {
        after: {
          5000: { target: 'creating' },
        },
      },
      created: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      incrementCurrentCreationIndex: assign((context) => {
        return { currentCreationIndex: context.currentCreationIndex + 1 }
      }),
      initCreateState: assign((context, event) => {
        if (event.type !== 'CREATE') {
          return context
        }

        const { purchasedGoods } = event

        const stages: Stages = {}
        purchasedGoods.forEach((purchasedGood) => {
          stages[purchasedGood.clientSideID] = {
            error: undefined,
            stage: 'creating',
          }
        })

        return {
          currentCreationIndex: 0,
          facilityID: event.facilityID,
          facilityNetworkID: event.facilityNetworkID,
          productionCycle: event.productionCycle,
          productionTerm: event.productionTerm,
          purchasedGoods,
          stages,
        }
      }),
      markError: assign((context, event) => {
        return {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          stages: updateStages({ context, error: event.data }),
        }
      }),
      markSuccess: assign((context) => {
        return {
          stages: updateStages({ context }),
        }
      }),
    },
    guards: {
      hasMoreItemsToCreate: (context) => {
        return context.currentCreationIndex < context.purchasedGoods.length
      },
    },
    services: {
      createShortPickOrder: (context) => {
        const {
          currentCreationIndex,
          facilityID,
          facilityNetworkID,
          productionCycle,
          productionTerm,
          purchasedGoods,
        } = context

        const purchasedGood = purchasedGoods[currentCreationIndex]

        return createShortPickOrderPurchasedGood({
          data: {
            baseSku: purchasedGood.item.value.baseSku,
            cycle: productionCycle,
            facilityID,
            facilityNetworkID,
            pounds: purchasedGood.count,
            termID: productionTerm,
            reason: purchasedGood.reason,
          },
        })
      },
    },
  }
)
